/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header/header';
import Footer from './Footer/footer';
import './layout.css';

const Layout = ({ children, headerSolidBottom }) => {
  return (
    <div className="layout">
      <Header solidBottom={headerSolidBottom} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.defaultProps = {
  headerSolidBottom: false,
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerSolidBottom: PropTypes.bool,
};

export default Layout;
