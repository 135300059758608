import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

import styles from './footer.module.css';
import TriangleDown from '../../images/empty-small-white-triangle-down.svg';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        photoMeSquare: file(relativePath: { eq: "photo-me-square.jpg" }) {
          childImageSharp {
            fixed(width: 70, height: 70) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.footerInner}>
            <div className={styles.logoAndCopy}>
              <img
                src={TriangleDown}
                className={styles.smallTriangle}
                alt="&#9661;"
                width="45"
                height="45"
              />
              <span className={styles.copy}>© 2020&#8209;23</span>
            </div>
            <div className={styles.menu}>
              <Link to="/" className={styles.menuLink}>
                Home
              </Link>
              <Link to="/about-me" className={styles.menuLink}>
                About me
              </Link>
              <Link to="/til" className={styles.menuLink}>
                TIL!
              </Link>
              <Link to="/sideprojects" className={styles.menuLink}>
                Sideprojects
              </Link>
              <Link to="/tools" className={styles.menuLink}>
                Tools
              </Link>
            </div>
            <div className={styles.break}></div>
            <div className={styles.greetingsBox}>
              <div className={styles.greetingsBoxText}>
                Thanks for stepping in!
                <br />~ Tomek
              </div>
              <div className={styles.greetingsBoxImageWrapper}>
                <Img
                  fixed={data.photoMeSquare.childImageSharp.fixed}
                  className={styles.greetingsBoxImage}
                  alt="Tomek"
                  width="70"
                  height="70"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
