/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, ogUrl, imageUrl, type }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitter
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title;
  const ogTitle = title ? title : site.siteMetadata.title;
  const ogType = type ? type : 'website';

  const metaTag = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: ogTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: ogType,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `author`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.twitter,
    },
    {
      name: `twitter:title`,
      content: ogTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  if (ogUrl) {
    metaTag.push({
      property: `og:url`,
      content: ogUrl,
    });
  }
  if (imageUrl) {
    const domain =
      process.env.NODE_ENV === 'production' ? 'https://tomekdev.com' : '';

    metaTag.push(
      {
        property: `og:image`,
        content: `${domain}${imageUrl}`,
      },
      {
        property: `og:image:width`,
        content: '1200',
      },
      {
        property: `og:image:height`,
        content: '630',
      }
    );
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={metaTag.concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ogUrl: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default SEO;
